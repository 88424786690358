import useRoles from 'common/hooks/useRoles';
import Script from 'next/script';

export default function TextRequestWidget() {
  const { isVendor } = useRoles();

  if (isVendor) {
    return (
      <>
        <Script
          src="https://fs.textrequest.com/sms-chat/main.bundle.js"
          defer
          strategy="lazyOnload"
        />
        {/* If modifying this, be sure to update/test the globalShards function */}
        <txr-sms-chat
          accountid="20314"
          chatid="8a5ee4dc-6366-4411-bca9-140db5c8ff43"
        />
      </>
    );
  }
  return null;
}
