import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { View } from 'components/Chat/useResponsiveChat';

export type ChatBotState = {
  isNewThreadMode: boolean;
  displayMode: View;
};

type RootState = {
  chatbot: ChatBotState;
};

const initialState: ChatBotState = {
  isNewThreadMode: false,
  displayMode: View.LIST,
};

const companySlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setIsNewThreadMode(state, { payload }: PayloadAction<boolean>) {
      state.isNewThreadMode = payload;
    },
    setDisplayMode(state, { payload }: PayloadAction<View>) {
      state.displayMode = payload;
    },
  },
});

export const { setDisplayMode, setIsNewThreadMode } = companySlice.actions;
export default companySlice.reducer;

export const selectNewThreadMode = (state: RootState): boolean =>
  state.chatbot.isNewThreadMode;

export const selectDisplayMode = (state: RootState): View =>
  state.chatbot.displayMode;
