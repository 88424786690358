import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useCallback } from 'react';
import { selectDisplayMode, setDisplayMode } from 'slices/chatbotSlice';
import useResponsive from 'styleguide/src/hooks/useResponsive';

export enum View {
  LIST,
  THREAD,
  DESKTOP,
}

export default function useResponsiveChat() {
  const { isLarge } = useResponsive();

  const activeView = useAppSelector(selectDisplayMode);
  const dispatch = useAppDispatch();

  const setActiveView = useCallback(
    (view: View) => {
      dispatch(setDisplayMode(view));
    },
    [dispatch],
  );

  return {
    view: isLarge ? View.DESKTOP : activeView,
    setView: setActiveView,
  };
}
