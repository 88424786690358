import { useGetBuildIdQuery } from 'common/services/endpoints/buildId';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const BUILD_REFRESH_POLL_RATE = 1000 * 60 * 5;

function BuildUpdater() {
  const [buildId, setBuildId] = useState<string>();
  const { data } = useGetBuildIdQuery(undefined, {
    pollingInterval: BUILD_REFRESH_POLL_RATE,
    skipPollingIfUnfocused: false,
  });
  const router = useRouter();

  useEffect(() => {
    if (data?.buildId) {
      if (!buildId) {
        setBuildId(data.buildId);
      } else if (buildId !== data.buildId) {
        router.reload();
      }
    }
  }, [buildId, data, router]);

  return null;
}

export default BuildUpdater;
