import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import type { BaseState } from 'common/store/types';
import type { AppDispatch } from 'common/store';
import { CompanyState } from 'common/store/slices/companySlice';
import { ChatBotState } from 'slices/chatbotSlice';

type AppRootState = BaseState & {
  company: CompanyState;
  chatbot: ChatBotState;
};
export const useAppDispatch = () => useDispatch<AppDispatch>();
// App specific wrapper for types
export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector;
