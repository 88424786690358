import { api } from 'common/store/api';

const FIVE_MIN = 1000 * 60 * 5;

const BUILD_ID = '/build-id';

const apiWithTag = api.enhanceEndpoints({
  addTagTypes: ['BuildId'],
});

const buildIdEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBuildId: builder.query<{ buildId?: string }, void>({
      query: () => ({
        url: BUILD_ID,
      }),
      providesTags: ['BuildId'],
      keepUnusedDataFor: FIVE_MIN,
    }),
  }),
  overrideExisting: true,
});

export const { useGetBuildIdQuery } = buildIdEndpoints;
